import gql from "graphql-tag";

export const querySkuAvailabilityStatus = gql`
  query SkuAvailabilityStatus(
    $sku: String!
    $country: String
    $postalCode: String
  ) {
    skuAvailabilityStatus(
      sku: $sku
      country: $country
      postalCode: $postalCode
    ) {
      sku
      maxQuantity
      quantityRequested
      availabilityStatus
      country
      postalCode
    }
  }
`;
